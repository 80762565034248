{
  "compressionlevel": -1,
  "height": 13,
  "infinite": false,
  "layers": [
    {
      "data": [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1107, 1108, 1112, 2307, 2312,
        2787, 2788, 2789, 2790, 2791, 2792, 2307, 2310, 2312, 1107, 1112, 0, 0,
        0, 0, 1187, 1268, 1272, 2347, 2392, 1107, 1108, 1109, 1110, 1111, 1112,
        2387, 2351, 2352, 1307, 1312, 0, 0, 0, 0, 1307, 1310, 1312, 2387, 2432,
        1147, 1151, 1149, 1150, 1271, 1152, 2427, 2471, 2472, 2402, 2363, 0, 0,
        0, 0, 2307, 2308, 2308, 2397, 2392, 1187, 1468, 1189, 1190, 1191, 1192,
        2387, 2671, 2672, 1107, 1112, 0, 0, 0, 0, 2467, 2468, 2350, 2351, 2432,
        1227, 1508, 1229, 1230, 1231, 1232, 2427, 2711, 2712, 1307, 1312, 0, 0,
        0, 0, 2507, 2508, 2317, 2391, 2392, 1267, 1268, 1269, 1270, 1151, 1272,
        2387, 2348, 2472, 2400, 2401, 0, 0, 0, 0, 1107, 1112, 2387, 2431, 2432,
        1307, 1308, 1309, 1310, 1311, 1312, 2427, 2348, 2392, 1107, 1112, 0, 0,
        0, 0, 1307, 1312, 2507, 2509, 2512, 2587, 2588, 2589, 2590, 2591, 2592,
        2507, 2510, 2512, 1307, 1312, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0
      ],
      "height": 13,
      "id": 2,
      "name": "gameBoard",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    },
    {
      "data": [
        3214, 3214, 3214, 3214, 3214, 3214, 3214, 3214, 3214, 3214, 3214, 3214,
        3214, 3214, 3214, 3214, 3214, 3214, 3214, 3214, 57, 58, 359, 360, 357,
        358, 359, 360, 170, 171, 172, 173, 363, 364, 365, 366, 363, 364, 367,
        368, 89, 90, 391, 392, 389, 390, 391, 392, 202, 203, 204, 205, 395, 396,
        397, 398, 395, 396, 399, 400, 556, 557, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 554, 555, 588, 589, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 586, 587, 742, 743, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 868, 869, 774, 775, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 900, 901, 806, 807, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 932, 933, 838, 839, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        964, 965, 684, 685, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 682,
        683, 716, 717, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 714, 715,
        291, 292, 295, 296, 293, 294, 295, 296, 110, 111, 112, 113, 299, 300,
        301, 302, 299, 300, 303, 304, 323, 324, 327, 328, 325, 326, 327, 328,
        142, 143, 144, 145, 331, 332, 333, 334, 331, 332, 335, 336
      ],
      "height": 13,
      "id": 4,
      "name": "outOfBounds",
      "opacity": 1,
      "type": "tilelayer",
      "visible": true,
      "width": 20,
      "x": 0,
      "y": 0
    }
  ],
  "nextlayerid": 3,
  "nextobjectid": 1,
  "orientation": "orthogonal",
  "renderorder": "right-down",
  "tiledversion": "1.11.0",
  "tileheight": 16,
  "tilesets": [
    {
      "columns": 32,
      "firstgid": 1,
      "image": "PWT_BasicBrick00.png",
      "imageheight": 512,
      "imagewidth": 512,
      "margin": 0,
      "name": "PWT_BasicBrick00",
      "spacing": 0,
      "tilecount": 1024,
      "tileheight": 16,
      "tilewidth": 16
    },
    {
      "columns": 40,
      "firstgid": 1025,
      "image": "PFT_GroundSand00.png",
      "imageheight": 480,
      "imagewidth": 640,
      "margin": 0,
      "name": "PFT_GroundSand00",
      "spacing": 0,
      "tilecount": 1200,
      "tileheight": 16,
      "tilewidth": 16
    },
    {
      "columns": 40,
      "firstgid": 2225,
      "image": "PFT_EngravedGreen00.png",
      "imageheight": 480,
      "imagewidth": 640,
      "margin": 0,
      "name": "PFT_EngravedGreen00",
      "spacing": 0,
      "tilecount": 1200,
      "tileheight": 16,
      "tilewidth": 16
    }
  ],
  "tilewidth": 16,
  "type": "map",
  "version": "1.10",
  "width": 20
}
